<template>
    <!-- FOOTER SECTION -->
    <footer>

    <div class="main_footer_section">
        <div class="main_footer_row main_container row_padding">
            <div class="main_footer_col main_footer_col_01">
                <div class="main_footer_block">
                    <div class="data_info_box">
                        <router-link to="/"><img :src="footerLogo" class="footer_logo"/></router-link>
                        <!-- <p class="small_body_font">Identification, assessment and distinction between positive and negative impacts, direct and indirect impacts.assessment and distinction.</p> -->
                        <p class="small_body_font">Rio Colina is a group of premium and sophisticated villas that promises to deliver experiences that are spirited, dynamic, and unique - a fit made for a perfect getaway.</p>
                    </div>
                </div>
            </div>
            <div class="main_footer_col main_footer_col_02">
                <div class="main_footer_block">
                    <div class="main_footer_title_box">
                        <p class="main_footer_title">Quick Links</p>
                    </div>
                    <div class="main_footer_text_outter_box">
                        <ul class="main_footer_text_box">
                            <li class="body_font"><router-link to="/platinum-villa">Platinum Villa</router-link></li>
                            <li class="body_font"><router-link to="/diamond-villa">Diamond Villa</router-link></li>
                            <li class="body_font"><router-link to="/cottage">Exclusive Cottages</router-link></li>
                            <li class="body_font"><router-link to="/one-day-picnic">Just One Day Picnic</router-link></li>
                            <li class="body_font"><router-link to="/wedding">Weddings/Functions</router-link></li>
                            <li class="body_font"><router-link to="/event">Corporate Events</router-link></li>
                            <li class="body_font"><router-link to="/events-rio">Events @ Rio</router-link></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <div class="main_footer_col main_footer_col_02">
                <div class="main_footer_block">
                    <div class="main_footer_title_box">
                        <p class="main_footer_title">Quick Links</p>
                    </div>
                    <div class="main_footer_text_outter_box">
                        <ul class="main_footer_text_box">
                            <li class="body_font"><router-link to="/photo">Gallery</router-link></li>
                            <li class="body_font"><router-link to="/#">Blog</router-link></li>
                            <li class="body_font"><router-link to="/contact-us">Contact Us</router-link></li>
                            <li class="body_font"><router-link to="/about-us">About Us</router-link></li>
                            <li class="body_font"><router-link to="/terms-and-condition">T&C</router-link></li>
                            <li class="body_font"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li class="body_font"><router-link to="/refund-and-return-policy">Refund and Return Policy</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="main_footer_col main_footer_col_03">
                <div class="main_footer_block">
                    <div class="main_footer_title_box">
                        <p class="main_footer_title">Get in touch</p>
                    </div>
                    <div class="main_footer_text_box">

                        <div class="cdetail_outter">
                            <div class="cdetail_inner">
                                <a href="tel:18008 913918">
                                    <div class="cdetail_icon">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <div class="cdetail_text">
                                        <p class="body_font">Call 18008 913918</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="cdetail_outter">
                            <div class="cdetail_inner">
                                <a href="mailto:receptionriocolina@gmail.com">
                                    <div class="cdetail_icon">
                                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="cdetail_text">
                                        <p class="body_font">mail: receptionriocolina@gmail.com</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="cdetail_outter">
                            <div class="cdetail_inner">
                                <a href="https://goo.gl/maps/FCYmq9nBEQzYvh7h8" target="_blank">
                                    <div class="cdetail_icon">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>
                                    <div class="cdetail_text">
                                        <p class="body_font">At & Post Ghala, Taluko Kamrej, Near Khumtai Mata Mandir, Surat, Gujarat 394155</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                    <div class="main_footer_title_box">
                        <p class="main_footer_title">Follow Us</p>
                    </div>
                    <div class="main_footer_text_box">
                        <div class="social_icons">
                            <a href="https://www.facebook.com/kkbriocolina/?ref=br_rs" class="round_icon" target="_blank"><i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                            <a href="https://www.instagram.com/rio_colina/" class="round_icon" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            <!-- <a href="https://www.youtube.com/channel/UCx7JKt5zYQE7-iltaJxVgNQ/videos" class="round_icon" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="cp_footer_row">
            <div class="cp_footer_col main_container">
                <p class="body_font tc">Copyright © {{ new Date().getFullYear() }} Rio Colina All Rights Reserved.</p>
                <p class="body_font tc">Owned by Rio Hill</p>
                <div class="link_class"><a href="https://primetechnosoft.in/" target="_blank" rel="noopener"><div class="cp_text02 footer-text wt">Made With<div class="heart"></div> By Prime</div></a></div>
            </div>
        </div>
    </div>

    <!-- ---------- STIKY FOOTER SECTION ---------- -->
    <div class="stiky_footer_section">
        <div class="stiky_footer_row">
            <div class="stiky_footer_col">
                <div class="stiky_footer_icons">
                    <a href="https://www.facebook.com/kkbriocolina/?ref=br_rs" class="stiky_footer_icon" target="_blank"><i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                    <a href="https://www.instagram.com/rio_colina/" class="stiky_footer_icon" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                    <a href="https://www.youtube.com/channel/UCx7JKt5zYQE7-iltaJxVgNQ/videos" class="stiky_footer_icon" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                    <a href="mailto:kkbriocolina@gmail.com" class="header_side_icon stiky_footer_icon"><i class="fa fa-envelope" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!-- ---------- END ---------- -->
    </footer>
    <!-- END -->
</template>

<script>
import footerLogo from "@/assets/images/header/footer-logo.png";
export default {
    name: "Footer",
    data() {
        return {
            footerLogo: footerLogo,
        }
    }
}
</script>