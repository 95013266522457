<template>
  <div class="header_section">
    <div class="header_row main_container">
      <!-- LOGO -->
      <div class="header_col header_center_col">
        <div class="header_logo_block">
          <router-link to="/"
            ><img :src="headerLogo" class="header_logo"
          /></router-link>
        </div>
      </div>
      <!-- END -->
      <div class="header_col header_left_col">
        <div class="header_social_block top_block">
          <div class="social_icons">
            <a
              href="https://www.facebook.com/kkbriocolina/?ref=br_rs"
              class="round_icon"
              target="_blank"
              ><i class="fa fa-facebook-official" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.instagram.com/rio_colina/"
              class="round_icon"
              target="_blank"
              ><i class="fa fa-instagram" aria-hidden="true"></i
            ></a>
            <!-- <a
              href="https://www.youtube.com/channel/UCx7JKt5zYQE7-iltaJxVgNQ/videos"
              class="round_icon"
              target="_blank"
              ><i class="fa fa-youtube-play" aria-hidden="true"></i
            ></a> -->
          </div>
          <div class="email_outter">
            <div class="email_inner">
              <a href="mailto:receptionriocolina@gmail.com">
                <div class="mail_icon">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
                <div class="mail_text">
                  <p class="body_font">Mail: receptionriocolina@gmail.com</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="header_left_menu">
          <nav id="Left_menu">
            <ul class="main_menu">
              <li :class="title == 'Home' || title == '' ? `nav-link` : ``">
                <router-link to="/">Home</router-link>
              </li>
              <li :class="title == 'AboutUs' ? `nav-link` : ``">
                <router-link to="/about-us">About</router-link>
              </li>
              <li class="menu-item-has-children">
                <a href="#">Villas On Rent</a>
                <ul class="sub-menu">
                  <li :class="title == 'Platinum' ? `nav-link` : ``">
                    <router-link to="/platinum-villa"
                      >Platinum Villa</router-link
                    >
                  </li>
                  <li :class="title == 'Diamond' ? `nav-link` : ``">
                    <router-link to="/diamond-villa">Diamond Villa</router-link>
                  </li>
                  <li :class="title == 'Cottages' ? `nav-link` : ``">
                    <router-link to="/cottage">Exclusive Cottages</router-link>
                  </li>
                </ul>
              </li>
              <li :class="title == 'OneDayPicnic' ? `nav-link` : ``">
                <router-link to="/one-day-picnic">One Day Picnic</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- LOGO -->
      <!-- <div class="header_col header_center_col">
        <div class="header_logo_block">
          <router-link to="/"><img :src="headerLogo" class="header_logo"/></router-link>
        </div>
      </div> -->
      <!-- END -->
      <div class="header_col header_right_col">
        <div class="header_contact_block top_block">
          <div class="bird bird--one">
            <div class="block_cta">
              <a href="tel:18008913918" class="site_button call_us_btn">
                <div class="site_button_div">
                  <!-- <img src='./images/header/call.png' class='btn_icon_img'> -->
                  <svg
                    class="btn_icon_img"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0171 5.67134C10.7394 5.67134 11.4321 5.95825 11.9428 6.46896C12.4535 6.97967 12.7404 7.67234 12.7404 8.3946C12.7404 8.63535 12.836 8.86624 13.0063 9.03647C13.1765 9.20671 13.4074 9.30235 13.6481 9.30235C13.8889 9.30235 14.1198 9.20671 14.29 9.03647C14.4603 8.86624 14.5559 8.63535 14.5559 8.3946C14.5559 7.19084 14.0777 6.03639 13.2265 5.18521C12.3753 4.33402 11.2209 3.85583 10.0171 3.85583C9.77638 3.85583 9.54549 3.95147 9.37525 4.12171C9.20501 4.29195 9.10938 4.52284 9.10938 4.76359C9.10938 5.00434 9.20501 5.23523 9.37525 5.40547C9.54549 5.5757 9.77638 5.67134 10.0171 5.67134Z"
                      fill="black"
                    />
                    <path
                      d="M10.0171 2.04036C11.7024 2.04036 13.3186 2.70982 14.5103 3.90148C15.7019 5.09314 16.3714 6.70937 16.3714 8.39462C16.3714 8.63538 16.467 8.86627 16.6373 9.0365C16.8075 9.20674 17.0384 9.30238 17.2791 9.30238C17.5199 9.30238 17.7508 9.20674 17.921 9.0365C18.0913 8.86627 18.1869 8.63538 18.1869 8.39462C18.1869 6.22787 17.3262 4.14985 15.794 2.61772C14.2619 1.08559 12.1839 0.224854 10.0171 0.224854C9.77638 0.224854 9.54549 0.320491 9.37525 0.490728C9.20501 0.660965 9.10938 0.891855 9.10938 1.13261C9.10938 1.37336 9.20501 1.60425 9.37525 1.77448C9.54549 1.94472 9.77638 2.04036 10.0171 2.04036Z"
                      fill="black"
                    />
                    <path
                      d="M17.9594 12.8517C17.9094 12.706 17.8232 12.5753 17.7089 12.4721C17.5946 12.3689 17.4558 12.2965 17.3058 12.2617L11.8593 11.018C11.7114 10.9845 11.5575 10.9885 11.4117 11.0298C11.2658 11.071 11.1326 11.1481 11.0241 11.254C10.897 11.3721 10.888 11.3811 10.2979 12.5067C8.34009 11.6046 6.77131 10.0294 5.87718 8.06783C7.03002 7.48687 7.0391 7.48687 7.15711 7.35071C7.26305 7.24228 7.34015 7.10905 7.38138 6.96317C7.42261 6.8173 7.42664 6.66342 7.39312 6.51558L6.1495 1.13261C6.11469 0.982551 6.04226 0.843823 5.93903 0.729484C5.83581 0.615144 5.70519 0.528953 5.55946 0.479024C5.34747 0.403308 5.12856 0.348581 4.90588 0.315629C4.67645 0.26243 4.44232 0.232024 4.20691 0.224854C3.09946 0.224854 2.03736 0.664788 1.25427 1.44788C0.471185 2.23096 0.03125 3.29306 0.03125 4.40051C0.0360538 8.1066 1.51042 11.6595 4.13102 14.2801C6.75163 16.9007 10.3045 18.3751 14.0106 18.3799C14.559 18.3799 15.102 18.2719 15.6086 18.062C16.1152 17.8522 16.5755 17.5446 16.9633 17.1569C17.351 16.7691 17.6586 16.3088 17.8684 15.8022C18.0783 15.2956 18.1863 14.7526 18.1863 14.2042C18.1866 13.9732 18.1684 13.7425 18.1318 13.5143C18.0937 13.2889 18.036 13.0672 17.9594 12.8517ZM14.0106 16.5644C10.7853 16.562 7.69278 15.2797 5.41213 12.999C3.13148 10.7184 1.84916 7.62584 1.84675 4.40051C1.84915 3.7753 2.09857 3.17637 2.54067 2.73427C2.98277 2.29218 3.58169 2.04275 4.20691 2.04036H4.50647L5.47776 6.25233L4.98758 6.5065C4.20691 6.91499 3.58964 7.24178 3.91643 7.94983C4.44858 9.45631 5.30962 10.8253 6.43702 11.9574C7.56441 13.0895 8.92984 13.9563 10.4341 14.4947C11.1966 14.8034 11.4962 14.2315 11.9047 13.4417L12.1679 12.9425L16.3708 13.9047V14.2042C16.3684 14.8295 16.119 15.4284 15.6769 15.8705C15.2348 16.3126 14.6359 16.562 14.0106 16.5644Z"
                      fill="black"
                    />
                  </svg>

                  <span class="btn_text">18008 913918</span>
                  <!-- <span class="btn_text">Call Us 18008 913918</span> -->
                </div>
              </a>
            </div>
          </div>
          <div class="bird bird--one" v-show="checkAuthentication">
            <div class="block_cta">
              <!-- <a href="javascript:void(0)" class="site_button dashboard_btn"> -->
              <router-link class="site_button dashboard_btn" to="/dashboard">
                <div class="site_button_div">
                  <svg
                    class="btn_icon_img"
                    width="19"
                    height="19"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM12 5C13.018 5 13.9852 5.21731 14.8579 5.60806L13.2954 7.16944C12.8822 7.05892 12.448 7 12 7C9.23858 7 7 9.23858 7 12C7 13.3807 7.55964 14.6307 8.46447 15.5355L7.05025 16.9497L6.89445 16.7889C5.71957 15.5368 5 13.8525 5 12C5 8.13401 8.13401 5 12 5ZM18.3924 9.14312C18.7829 10.0155 19 10.9824 19 12C19 13.933 18.2165 15.683 16.9497 16.9497L15.5355 15.5355C16.4404 14.6307 17 13.3807 17 12C17 11.552 16.9411 11.1178 16.8306 10.7046L18.3924 9.14312ZM16.2426 6.34315L17.6569 7.75736L13.9325 11.483C13.9765 11.6479 14 11.8212 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C12.1788 10 12.3521 10.0235 12.517 10.0675L16.2426 6.34315Z"
                    ></path>
                  </svg>
                  <span class="btn_text">Dashboard</span>
                </div>
              </router-link>
              <!-- </a> -->
            </div>
          </div>
          <div class="bird bird--one">
            <div class="login_btn_box">
              <a
                href="javascript:void(0)"
                class="site_button login_btn"
                v-show="!checkAuthentication"
                @click="popupMobile = !popupMobile"
              >
                <div class="site_button_div">
                  <!-- <img src='./images/header/user.png' class='btn_icon_img'> -->
                  <svg
                    class="btn_icon_img"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8113 8.73992C11.5881 8.12881 12.155 7.2908 12.4333 6.34247C12.7116 5.39414 12.6874 4.38265 12.364 3.44873C12.0407 2.5148 11.4342 1.70488 10.6292 1.13165C9.82407 0.558412 8.86032 0.250366 7.87201 0.250366C6.88369 0.250366 5.91995 0.558412 5.11486 1.13165C4.30976 1.70488 3.70336 2.5148 3.38001 3.44873C3.05666 4.38265 3.03244 5.39414 3.31073 6.34247C3.58901 7.2908 4.15596 8.12881 4.93269 8.73992C3.60174 9.27315 2.44044 10.1576 1.57259 11.2989C0.704747 12.4402 0.162886 13.7956 0.00477884 15.2207C-0.0066658 15.3247 0.00249436 15.43 0.0317361 15.5305C0.0609779 15.631 0.109729 15.7248 0.175205 15.8064C0.30744 15.9713 0.499775 16.077 0.709898 16.1001C0.920021 16.1232 1.13072 16.0619 1.29564 15.9297C1.46056 15.7974 1.5662 15.6051 1.58932 15.395C1.76329 13.8462 2.50176 12.4159 3.66365 11.3772C4.82554 10.3385 6.32938 9.76434 7.88785 9.76434C9.44633 9.76434 10.9502 10.3385 12.1121 11.3772C13.2739 12.4159 14.0124 13.8462 14.1864 15.395C14.2079 15.5896 14.3008 15.7694 14.4471 15.8997C14.5934 16.0299 14.7828 16.1013 14.9787 16.1001H15.0658C15.2735 16.0762 15.4633 15.9712 15.5939 15.8079C15.7245 15.6447 15.7853 15.4365 15.763 15.2286C15.6041 13.7995 15.0594 12.4406 14.1871 11.2975C13.3148 10.1545 12.1478 9.27032 10.8113 8.73992ZM7.87201 8.17741C7.24522 8.17741 6.63251 7.99154 6.11136 7.64332C5.59021 7.2951 5.18402 6.80016 4.94416 6.22109C4.7043 5.64201 4.64154 5.00482 4.76382 4.39008C4.8861 3.77534 5.18793 3.21066 5.63113 2.76746C6.07434 2.32425 6.63901 2.02243 7.25375 1.90015C7.86849 1.77787 8.50569 1.84063 9.08476 2.08049C9.66383 2.32035 10.1588 2.72654 10.507 3.24769C10.8552 3.76884 11.0411 4.38155 11.0411 5.00833C11.0411 5.84882 10.7072 6.65489 10.1129 7.24921C9.51856 7.84352 8.7125 8.17741 7.87201 8.17741Z"
                      fill="black"
                    />
                  </svg>
                  <span class="btn_text">Login</span>
                </div>
              </a>
              <a
                href="javascript:void(0)"
                class="site_button login_btn"
                v-show="checkAuthentication"
                @click="logout"
              >
                <div class="site_button_div">
                  <svg
                    class="btn_icon_img"
                    width="19"
                    height="19"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C15.2713 2 18.1757 3.57078 20.0002 5.99923L17.2909 5.99931C15.8807 4.75499 14.0285 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C14.029 20 15.8816 19.2446 17.2919 17.9998L20.0009 17.9998C18.1765 20.4288 15.2717 22 12 22ZM19 16V13H11V11H19V8L24 12L19 16Z"
                    ></path>
                  </svg>
                  <span class="btn_text">Logout</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="header_right_menu">
          <nav id="right_menu">
            <ul class="main_menu">
              <li :class="title == 'Wedding' ? `nav-link` : ``">
                <router-link to="/wedding"> Weddings/Functions </router-link>
              </li>
              <!-- <li :class="(title == 'Event') ? `nav-link` : ``">
                <router-link to="/event">Events</router-link>
              </li> -->
              <li class="menu-item-has-children">
                <a href="#">Events</a>
                <ul class="sub-menu">
                  <li :class="title == 'Event' ? `nav-link` : ``">
                    <router-link to="/event">Corporate Events</router-link>
                  </li>
                  <li :class="title == 'EventAtRio' ? `nav-link` : ``">
                    <router-link to="/events-rio">Events @ Rio</router-link>
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children">
                <a href="#">Gallery</a>
                <ul class="sub-menu">
                  <li :class="title == 'Photo' ? `nav-link` : ``">
                    <router-link to="/photo">Photos</router-link>
                  </li>
                  <li :class="title == 'Video' ? `nav-link` : ``">
                    <router-link to="/video">Videos</router-link>
                  </li>
                </ul>
              </li>
              <li :class="title == 'ContactUs' ? `nav-link` : ``">
                <router-link to="/contact-us">Contact Us</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="mobile_header_row main_container" :class="{'nav-is-toggled':is_menu_open == true}">
      <div class="mobile_col mobile_header_nav_menu_col">
        <div class="mobile_header_nav_menu_block">
          <!-- <span class="hamburger material-icons" id="ham">menu</span> -->
          <span class="hamburger material-icons menu-icon" @click="openMenu(true)"
            ><i class="fa fa-bars" aria-hidden="true"></i
          ></span>
          <span class="hamburger material-icons close-icon" @click="openMenu(false)" 
            ><i class="fa fa-times" aria-hidden="true"></i
          ></span>

          <!-- <div class="" @click="myFunction(this)">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div> -->
        </div>
      </div>
      <div class="mobile_col mobile_header_logo_col">
        <div class="mobile_header_logo_block header_logo_block">
          <router-link to="/"
            ><img :src="headerLogo" class="header_logo"
          /></router-link>
        </div>
      </div>
      <div class="mobile_col mobile_header_contact_icon_col">
        <div class="mobile_header_contact_icon_block">
          <div class="header_side_icons">
            <a href="tel:18008913918" class="header_side_icon"
              ><i class="fa fa-phone" aria-hidden="true"></i
            ></a>
            <!-- <a href='javascript:void(0)' class='stiky_footer_icon'><i class='fa fa-user' aria-hidden='true'></i></a> -->
            <a
              href="javascript:void(0)"
              class="site_button login_btn"
              v-show="!checkAuthentication"
              @click="popupMobile = !popupMobile"
            >
              <div class="site_button_div">
                <!-- <img src='./images/header/user.png' class='btn_icon_img'> -->
                <i class="fa fa-user" aria-hidden="true"></i>
              </div>
            </a>
            <router-link
              class="header_side_icon"
              to="/dashboard"
              v-show="checkAuthentication"
              ><i class="fa fa-tachometer" aria-hidden="true"></i
            ></router-link>
            <a
              href="javascript:void(0)"
              class="header_side_icon"
              v-show="checkAuthentication"
              @click="logout"
              ><i class="fa fa-sign-out" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
      <nav class="nav-drill" :class="{'nav-is-toggled':is_menu_open == true}">
        <ul class="nav-items nav-level-1">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about-us">About</router-link>
          </li>
          <li class="nav-item nav-expand" :class="{'active':is_expand == true && is_expand_number == 1}">
            <a class="nav-link nav-expand-link" href="#" @click="exapandMenu(true, 1)"> Villas On Rent </a>
            <ul class="nav-items nav-expand-content">
              <li class="nav-item nav-close" @click="exapandMenu(false, 1)">
                <a class="nav-link" href="#"> Back </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/platinum-villa"
                  >Platinum Villa</router-link
                >
              </li>
              <!-- :class="(title == 'Platinum') ? `nav-link` : ``" -->
              <li class="nav-item">
                <!-- <a class="nav-link" href="#"> Diamond Villa </a> -->
                <router-link class="nav-link" to="/diamond-villa"
                  >Diamond Villa</router-link
                >
              </li>
              <li class="nav-item">
                <!-- <a class="nav-link" href="#"> Excusive Cottages </a> -->
                <router-link class="nav-link" to="/cottage"
                  >Cottage Villa</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/one-day-picnic"
              >One Day Picnic</router-link
            >
            <!-- :class="(title == 'OneDayPicnic') ? `nav-link` : ``" -->
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/wedding">
              Weddings/Functions
            </router-link>
            <!-- :class="(title == 'Wedding') ? `nav-link` : ``" -->
          </li>
          <!-- <li class="nav-item"> -->
          <!-- <router-link class="nav-link" to="/event">
              Corporate Events
            </router-link>  -->
          <!-- :class="(title == 'Event') ? `nav-link` : ``" -->
          <!-- <li class="nav-item nav-close">
                <a href="#">Events</a>
                <ul class="sub-menu">
                  <li :class="(title == 'Event') ? `nav-link` : ``">
                    <router-link to="/event">Corporate Events</router-link>
                  </li>
                  <li :class="(title == 'EventAtRio') ? `nav-link` : ``">
                    <router-link to="/eventatrio">Event @ RIO</router-link>
                  </li>
                </ul>
              </li> -->
          <!-- </li> -->
          <li class="nav-item nav-expand" :class="{'active':is_expand == true && is_expand_number == 2}">
            <a class="nav-link nav-expand-link" href="#" @click="exapandMenu(true, 2)"> Event </a>
            <ul class="nav-items nav-expand-content">
              <li class="nav-item nav-close" @click="exapandMenu(false, 2)">
                <a class="nav-link" href="#"> Back </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/event"
                  >Corporate Events</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/events-rio"
                  >Events @ Rio</router-link
                >
              </li>
              <!-- :class="(title == 'Platinum') ? `nav-link` : ``" -->
              <li class="nav-item">
                <!-- <a class="nav-link" href="#"> Diamond Villa </a> -->
                <!-- <router-link class="nav-link" to="/eventatrio">Event @ RIO</router-link> -->
              </li>
            </ul>
          </li>
          <li class="nav-item nav-expand" :class="{'active':is_expand == true && is_expand_number == 3}">
            <a class="nav-link nav-expand-link" href="#" @click="exapandMenu(true, 3)"> Gallery </a>
            <ul class="nav-items nav-expand-content">
              <li class="nav-item nav-close" @click="exapandMenu(false, 3)">
                <a class="nav-link" href="#"> Back </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/photo"> Photos </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/video"> Video </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact-us">
              Contact Us
            </router-link>
            <!-- :class="(title == 'ContactUs') ? `nav-link` : ``" -->
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <Login :popupMobile="popupMobile" @update="onUpdate($event)" />
</template>

<script>
import headerLogo from "@/assets/images/header/header_logo.png";
import Login from "./LoginPopup.vue";
import axios from "axios";

export default {
  name: "Header",
  components: { Login },
  props: {
    title: String,
  },
  data() {
    return {
      is_menu_open: false,
      is_expand: false,
      is_expand_number: 0,
      headerLogo: headerLogo,
      popupMobile: false,
      checkAuthentication: localStorage.getItem("auth_cus_token")
        ? localStorage.getItem("auth_cus_token")
        : null,
    };
  },
  methods: {
    openMenu(action) {
      this.is_menu_open = action;
    },
    exapandMenu(action, number) {
      this.is_expand = action;
      this.is_expand_number = number;
    },
    onUpdate(updatedData) {
      this.popupMobile = updatedData;
    },
    async logout() {
      await axios.get(process.env.VUE_APP_API_URL + "/csrf").then(async () => {
        await axios
          .get(process.env.VUE_APP_API_CUS_URL + "/logout", {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.checkAuthentication}`,
            },
          })
          .then((response) => {
            this.$toast.success(response.data.message);
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            localStorage.removeItem("id");
            localStorage.removeItem("check_in_date");
            localStorage.removeItem("check_out_date");
            localStorage.removeItem("total_child");
            localStorage.removeItem("total_adult");
            localStorage.removeItem("qty");

            localStorage.removeItem("total");
            localStorage.removeItem("festival_charge");
            localStorage.removeItem("gst_tax");
            localStorage.removeItem("gst_tax_percent");
            localStorage.removeItem("extra_person_charge");

            this.$router.go("/");
          });
      });
    },
  },
};
</script>

