<template>
  <!-- login form popup -->
  <div class="login_section" :class="popupMobile ? `login_show` : ``">
    <div class="login_row">
      <div class="login_col">
        <div class="modal__overlay"></div>
        <!-- phone number -->
        <div
          class="phone__wrapper_box phone_box"
          :class="popupMobile ? `animate__swing animate__animated` : ``"
          :style="{ display: popupMobile ? 'block' : 'none' }"
        >
          <button class="closeBtn" @click="close()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>

          <div class="login_box">
            <div class="login_logo">
              <router-link to="/"
                ><img :src="headerLogo" class="header_logo"
              /></router-link>
            </div>
            <div class="login_title">
              <span>Log in</span>
            </div>
            <hr />
            <div class="login_text">
              <span>welcome to Rio Colina!</span>
            </div>
            <div class="login_inr_text">
              <span>Let's log you in</span>
            </div>
          </div>
          <div class="login_form_col">
            <form method="POST" @submit.prevent="submitForm">
              <!-- @submit.prevent="submitForm" -->
              <div class="input__form">
                <input
                  id="input"
                  v-model="mobile"
                  type="number"
                  name="mobile"
                  placeholder=" "
                  class="input__input"
                  style="padding-left: 3.4rem"
                />
                <label for="mobileNumber" class="input__code">+91</label>
                <label
                  for="mobileNumber"
                  class="input__label"
                  id="mobile"
                  style="left: 3rem"
                  >Mobile Number</label
                >
                <p class="mobileError">{{ mobileError }}</p>
              </div>
              <button
                type="submit"
                class="phone_Button login_btn_poup site_button"
              >
                Send OTP
              </button>
              <!-- @click="submitForm" -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="login_section otp" :class="popupOtp ? `login_show` : ``">
    <div class="login_row">
      <button class="closeBtn" @click="closeOtp()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div class="login_col">
        <div class="modal__overlay"></div>
        <div
          class="otp otp_box"
          v-show="openOtp"
          :style="{ display: popupOtp }"
        >
          <div class="otp__container">
            <div class="otp__sub-container">
              <div>
                <div class="otp__header">
                  <div class="otp__header-title">
                    <p>Enter OTP</p>
                  </div>
                  <div class="otp__header-subtitle">
                    <p>
                      Please enter the code we've sent via SMS to {{ mobile }}
                      <span
                        style="cursor: pointer"
                        class="change_link"
                        @click="[(openOtp = false), (popupOtp = false)]"
                        >Change</span
                      >
                    </p>
                    <!-- , popupMobile = true -->
                  </div>
                </div>
                <div>
                  <form
                    method="POST"
                    style="display: block"
                    class="digit-group"
                    @submit.prevent="otpSubmitForm"
                  >
                    <div class="form__input">
                      <div class="otpBox">
                        <div class="singalBoxs">
                          <input
                            v-for="(value, index) in otpValues"
                            :key="index"
                            type="number"
                            maxlength="1"
                            class="otp-input"
                            :value="value"
                            @input="handleInput(index, $event.target.value)"
                            @keydown.backspace="handleBackspace(index)"
                            ref="otpFields"
                          />
                        </div>
                      </div>
                    </div>
                    <p class="otpError">{{ otpError }}</p>
                    <div class="otp_button mb-1">
                      <button
                        type="submit"
                        class="primaryButton login_btn_poup site_button"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </form>
                  <div class="otp__resend">
                    <p style="cursor: pointer" @click="submitForm">
                      Haven't received a code? <span>Resend now</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import headerLogo from "@/assets/images/header/header_logo.png";

export default {
  name: "Login",
  props: {
    popupMobile: Boolean,
  },
  data() {
    return {
      openOtp: false,
      popupOtp: false,
      mobile: null,
      mobileError: null,
      otpError: null,
      otp1: null,
      otp2: null,
      otp3: null,
      otp4: null,
      headerLogo: headerLogo,
      otpValues: ["", "", "", ""],
    };
  },
  methods: {
    close() {
      this.$emit("update", false);
    },
    closeOtp() {
        this.openOtp = false;
        this.popupOtp = false;
        this.otpError = null;
        this.otp1 = null;
        this.otp2 = null;
        this.otp3 = null;
        this.otp4 = null;
        this.otpValues = ["", "", "", ""];
    },
    handleInput(index, value) {
      if (value.length > 1) {
        return;
      }
      this.otpValues[index] = value;
      if (value.length === 1 && index < this.otpValues.length - 1) {
        this.$refs.otpFields[index + 1].focus();
      }
      //   console.log(this.otpValues.toString().replaceAll(",", ""));
    },
    handleBackspace(index) {
      if (this.otpValues[index] !== "") {
        this.otpValues[index] = "";
      } else if (index > 0) {
        this.$refs.otpFields[index - 1].focus();
      }
    },
    async submitForm() {
      this.mobileError = null;
      if (!this.mobile) {
        this.mobileError = "Enter mobile number!";
      } else if (this.mobile && this.mobile.toString().length != 10) {
        this.mobileError = "Enter valid mobile number!";
      } else {
        await axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/customer-login",
            {
              mobile: this.mobile,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.disabled = false;
            if (response.data.status) {
              this.openOtp = true;
              this.popupOtp = true;
              localStorage.setItem("auth_otp", response.data.data.otp);
              localStorage.setItem(
                "auth_user_type",
                response.data.data.user_type
              );
            } else {
              if (response.data.error?.[0] === undefined) {
                this.mobileError = response.data?.message;
              } else {
                this.mobileError = response.data.error?.[0];
              }
            }
          })
          .catch(() => {
            this.disabled = false;
          });
      }
    },
    async otpSubmitForm() {
      // if (this.otp1.toString().length === 0 || this.otp2.toString().length === 0 || this.otp3.toString().length === 0 || this.otp4.toString().length === 0) {
      if (this.otpValues.length === 0) {
        this.otpError = "block";
      } else {
        await axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/verify-otp",
            {
              type: "web",
              mobile: this.mobile,
              // otp: `${this.otp1}${this.otp2}${this.otp3}${this.otp4}`,
              otp: this.otpValues.toString().replaceAll(",", ""),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.disabled = false;
            if (response.data.status) {
              this.openOtp = false;
              this.popupOtp = false;
              // this.popupMobile = false;
              //   this.$toast.success(response.data.message);
              localStorage.setItem("auth_cus_token", response.data.data);
              // this.$router.go('/dashboard')
              this.$router.push({ name: "dashboard" });
            } else {
              if (response.data.error?.[0] === undefined) {
                this.otpError = response.data?.message;
                // this.$toast.error(response.data?.message);
              } else {
                this.otpError = response.data.error?.[0];
                // this.$toast.error(response.data.error?.[0]);
              }
            }
          })
          .catch((error) => {
            // this.$toast.error(error);
            console.log("login res: ", error);
            this.disabled = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.otp .closeBtn {
    right: 10px;
    top: 10px;
}
.closeBtn {
  float: right;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  background-color: transparent;
  border: none;
}

.otp-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.otpBox {
  display: flex;
  align-items: center;
  width: 59%;
  margin: 20px auto;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  margin: 0 10px;
  border: 2px solid #dcf1be;
  box-shadow: none;
  background-color: transparent;
  color: #333;
  transition: all 0.3s ease-in-out;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 10px;
}

.mobileError {
  color: red;
  margin-top: 15px;
}

.otpError {
  color: red;
}

.otp__resend {
  margin-top: 15px;
}

.otp-input:focus {
  outline: none;
  border-bottom-color: #7da741;
}

@media all and (min-width: 320px) and (max-width: 480px) {
  .otp-input {
    /* width: 100%; */
    height: 40px;
    text-align: center;
    font-size: 24px;
    margin: 0 10px;
    border: 2px solid #dcf1be;
    box-shadow: none;
    background-color: transparent;
    color: #333;
    transition: all 0.3s ease-in-out;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 10px;
  }

  .otpBox .singalBoxs {
    display: flex;
  }

  .otpBox {
    align-items: center;
    width: 92%;
    margin: 20px auto;
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
  .otp-input {
    /* width: 100%; */
    height: 40px;
    text-align: center;
    font-size: 24px;
    margin: 0 10px;
    border: 2px solid #dcf1be;
    box-shadow: none;
    background-color: transparent;
    color: #333;
    transition: all 0.3s ease-in-out;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 10px;
  }

  .otpBox .singalBoxs {
    display: flex;
  }

  .otpBox {
    align-items: center;
    width: 67%;
    margin: 20px auto;
  }
}
</style>
